<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>Configurator</h1>
                    <router-link class="btn" :to="`/dispute/configurator/steps`" active-class="active" style="float: left;">Steps</router-link>
                    <router-link class="btn" :to="`/dispute/configurator/variables`" active-class="active" style="float: left; margin-left: 15px;">template variables</router-link>
                    <router-link class="btn" to="/dispute/employee/group/show" active-class="active" style="float: right;">Back</router-link>
                </div>
            </div>
            <form @submit.prevent="onConfigSubmit" class="row" style="margin-top: 40px;">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Dispute Configurator</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label for="ch1" class="input-group">
                                    <span class="input-group-addon">Disable email sending at all</span>
                                    <input class="form-control" v-model="config.disableEmailSendingAtAll" id="ch1" type="checkbox">
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="ch2" class="input-group">
                                    <span class="input-group-addon">Send emails to test mailbox</span>
                                    <input class="form-control" v-model="config.sendEmailsToTestMailbox" id="ch2" type="checkbox">
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="ch3" class="input-group">
                                    <span class="input-group-addon">Send emails from test mailbox</span>
                                    <input class="form-control" v-model="config.sendEmailsFromTestMailbox" id="ch3" type="checkbox">
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="ch4" class="input-group">
                                    <span class="input-group-addon">Test mailbox</span>
                                    <input class="form-control" v-model="config.testMailbox" id="ch4" type="email">
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="ch5" class="input-group">
                                    <span class="input-group-addon">Dispute mailbox name</span>
                                    <input class="form-control" v-model="config.disputeMailboxName" id="ch5" type="email">
                                </label>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>
                            <button type="submit" class="btn">Submit</button>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </form>
        </div>
    </div>
</template>

<script>

	export default {
		name: "Configurator",
        data() {
			return {
                config: {}
            }
        },
        mounted() {
			this.getConfigurator()
        },
        methods: {
	        getConfigurator() {
	        	disputeAxios
                    .get(`/api/configurations/get`)
                    .then(({data}) => this.config = data)
                    .catch(err => console.error(err))
            },
	        onConfigSubmit() {
	            if (!Helper.validateMail(this.config.testMailbox) && !Helper.validateMail(this.config.disputeMailboxName)) {
		            EventBus.$emit('showAlertModal', 'Please enter valid emails')
	            	return false
	            }

	        	let data = {
			        disableEmailSendingAtAll: this.config.disableEmailSendingAtAll,
			        sendEmailsToTestMailbox: this.config.sendEmailsToTestMailbox,
			        sendEmailsFromTestMailbox: this.config.sendEmailsFromTestMailbox,
			        testMailbox: this.config.testMailbox,
			        disputeMailboxName: this.config.disputeMailboxName
                }

		        disputeAxios
			        .post(`/api/configurations/set`, data)
			        .then(({data}) => data.code === 2004 ? EventBus.$emit('showAlertModal', 'Saved') : null)
			        .catch(err => console.error(err))
            }
		}
	}
</script>

<style scoped>
    .input-group-addon {
        width: 50%;
    }
    .form-control{
        align-self: center;
    }
    .input-group {
        cursor: pointer;
        margin-bottom: 0;
    }
    .table {
        width: 60%;
        margin: 0 auto;
        border: none;
        border-right: 1px solid #032438;
        border-left: 1px solid #032438;
    }
    .form-control:focus {
        box-shadow: none !important;
    }
</style>